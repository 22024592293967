import React, { useEffect, useState } from 'react'

import Logo from '../../assets/images/logo-vertical.svg'
import GooglePlay from '../../assets/images/google-store.svg'
import AppleStore from '../../assets/images/apple-store.svg'

const Download = () => {
  const [img, setImg] = useState()
  const [link, setLink] = useState()

  useEffect(() => {
    if (/iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent)) {
      setImg(AppleStore)
      setLink('https://apps.apple.com/ph/app/servbees/id1530137634')
    } else {
      setImg(GooglePlay)
      setLink('https://play.google.com/store/apps/details?id=com.servbees')
    }
  }, [])

  return (
    <div className="shared-link">
      <div className="container">
        <div className="content-wrapper">
          <img src={Logo} alt="Servbees Logo" className="img" />
          <h3 className="title-heading">
            Your friendly neighborhood pagkakakita-app
          </h3>
          <p className="desc">
            Join Servbees and bee ready to sell, offer services, and find what
            you need within your community.
          </p>
        </div>
        <div className="content-wrapper">
          <div className="desktop">
            <a
              href="https://play.google.com/store/apps/details?id=com.servbees"
              className="btn-google">
              <img src={GooglePlay} alt="download on google play store" />
            </a>
            <a
              href="https://apps.apple.com/ph/app/servbees/id1530137634"
              className="btn-apple">
              <img src={AppleStore} alt="download on apple store" />
            </a>
          </div>
          <div className="mobile">
            <a href={link} className="btn-apple">
              <img src={img} alt="download app" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Download
