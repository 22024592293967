import adminLogin from './admin/login'
import adminDashboard from './admin/dashboard'
import transactionsRoute from './admin/transactions'
import transactionDetailsRoute from './admin/transaction-details-route'
import payoutsRoute from './admin/payouts'
import payoutDetailsRoute from './admin/payout-details'
import payoutHistoryRoute from './admin/payout-history'

import download from './webapp/download'
import paymentStatus from './webapp/payment-status'

const adminRoute = [
  adminLogin,
  adminDashboard,
  transactionDetailsRoute,
  transactionsRoute,
  payoutDetailsRoute,
  payoutsRoute,
  payoutHistoryRoute,
]
const webappRoute = [download, paymentStatus]

export default [...adminRoute, ...webappRoute]
