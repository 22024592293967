import React, { useCallback, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import Sidepanel from '../../layouts/Sidepanel'
import Api from '../../services/Api'
import { useHistory } from 'react-router'
import { format } from 'date-fns'

const PayoutHistory = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [payouts, setPayouts] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const history = useHistory()

  const [filters, setFilters] = useState({
    page: 0,
    limit: 10,
    sort: 'date_modified_desc',
    method: null,
    status: 'paid',
  })

  const columns = React.useMemo(
    () => [
      {
        name: 'Amount',
        selector: 'total',
        sortable: true,
        cell: row =>
          `₱${(row.total ? row.total / 100 : 0).toLocaleString('en-US', {
            minimumFractionDigits: 2,
          })}`,
      },
      {
        name: 'Method',
        selector: 'payout_method',
        cell: row => (row.payout_method ? row.payout_method : <span>N/A</span>),
      },
      {
        name: 'Account Name',
        selector: 'account_name',
        sortable: true,
        cell: row => (row.account_name ? row.account_name : <span>N/A</span>),
      },
      {
        name: 'Account Number',
        selector: 'account_number',
        cell: row =>
          row.account_number ? row.account_number : <span>N/A</span>,
      },
      {
        name: 'status',
        selector: 'status',
        cell: row => (row.status === 'paid' ? 'released' : row.status),
      },
      {
        name: 'Date Last Updated',
        selector: 'date_modified',
        sortable: true,
        cell: row =>
          row.date_modified ? (
            <span>
              {format(
                row.date_modified?._seconds * 1000,
                'MMM dd, yyyy hh:mm:ii'
              )}
            </span>
          ) : (
            <span>N/A</span>
          ),
        width: '180px',
      },
    ],
    []
  )

  const fetchData = useCallback(async () => {
    setIsLoading(true)

    try {
      const { page, limit, sort, method, status } = filters
      const response = await Api.getPayouts({
        page,
        limit,
        sort,
        method,
        status,
      })

      if (!response.success) throw new Error(response.message)
      const { data, total_items } = response

      setTotalItems(total_items)
      setPayouts(data)
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }, [filters])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleOnSort = (column, sort) => {
    setFilters(filters => ({ ...filters, sort: `${column.selector}_${sort}` }))
  }

  const handleOnChangeRowsPerPage = rowsPerPage => {
    setFilters(filters => ({ ...filters, limit: rowsPerPage }))
  }

  const handleOnChangePage = page => {
    setFilters(filters => ({ ...filters, page: page - 1 }))
  }

  const handleOnRowClick = row => {
    if (!row.id) return
    history.push(`/admin/payouts/${row.id}`)
  }

  return (
    <>
      <div className="payout-history">
        <Sidepanel />
        <div className="payout-history-content">
          <div className="payout-history-content-label">
            <h2 className="display-5">Payout History</h2>
          </div>

          <div className="datatable-wrapper">
            <DataTable
              data={payouts}
              columns={columns}
              noHeader={true}
              pagination={true}
              paginationTotalRows={totalItems}
              paginationServer={true}
              sortServer={true}
              onSort={handleOnSort}
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              onChangeRowsPerPage={handleOnChangeRowsPerPage}
              onChangePage={handleOnChangePage}
              onRowClicked={handleOnRowClick}
              responsive={true}
              fixedHeader={true}
              progressPending={isLoading}
              defaultSortField={'account_name'}
              defaultSortAsc={false}
              paginationComponentOptions={{
                rowsPerPageText: 'View',
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default PayoutHistory
