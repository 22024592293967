import { initializeApp } from 'firebase/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/database'
import 'firebase/compat/storage'
import { getRemoteConfig, fetchAndActivate } from 'firebase/remote-config'

export const firebaseInit = async () => {
  try {
    let config = {}
    if (process.env.REACT_APP_NODE_ENV === 'develop') {
      config = {
        apiKey: 'AIzaSyB_XjdMbFkDjKTa5Ysq4D-N2XqiNduqFBA',
        authDomain: 'codes-servbees.firebaseapp.com',
        databaseURL: 'https://codes-servbees.firebaseio.com',
        projectId: 'codes-servbees',
        storageBucket: 'codes-servbees.appspot.com',
        messagingSenderId: '960850345935',
        appId: '1:960850345935:web:1caa66105824e97d079cba',
        measurementId: 'G-Y3MPRL957F',
      }
    } else if (process.env.REACT_APP_NODE_ENV === 'staging') {
      config = {
        apiKey: 'AIzaSyBaZvuPRMw6Bd9yvkRilc38yZoHxgk-iEA',
        authDomain: 'servbees-staging.firebaseapp.com',
        projectId: 'servbees-staging',
        storageBucket: 'servbees-staging.appspot.com',
        messagingSenderId: '746436195177',
        appId: '1:746436195177:web:d8b87700642d6fbc6503f8',
      }
    } else if (process.env.REACT_APP_NODE_ENV === 'production') {
      config = {
        apiKey: 'AIzaSyAm_56qhUJYtXnxmimMUAX3fxMJURemHsc',
        authDomain: 'servbees.firebaseapp.com',
        databaseURL: 'https://servbees.firebaseio.com',
        projectId: 'servbees',
        storageBucket: 'servbees.appspot.com',
        messagingSenderId: '78822170583',
        appId: '1:78822170583:web:9cf3d9a5c3b2a76f723592',
        measurementId: 'G-7WBZXMYYFV',
      }
    }

    const firebaseApp = initializeApp(config)
    const remoteConfig = getRemoteConfig(firebaseApp)
    await fetchAndActivate(remoteConfig)

    return firebaseApp
  } catch (error) {
    if (!/already exists/.test(error.message)) {
      console.error('Firebase initialization error', error.stack)
    }
  }
}
