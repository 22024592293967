import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { ContextProvider } from './context'
import PageNotFound from './pages/PageNotFound'

import routes from './routers'
import { firebaseInit } from './utils/firebase'

export const firebaseApp = firebaseInit()

function App() {
  return (
    <div className="App">
      <ContextProvider>
        <Router>
          <Switch>
            {routes.map(({ title, component: Component, url, exact }) => {
              return (
                <Route
                  key={url}
                  path={url}
                  exact={exact}
                  render={compProps => (
                    <Component {...compProps} title={title} />
                  )}
                />
              )
            })}
            <Route component={PageNotFound} />
          </Switch>
        </Router>
      </ContextProvider>
    </div>
  )
}

export default App
