import { getAuth } from 'firebase/auth'
import { getRemoteConfig } from 'firebase/remote-config'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { firebaseApp } from '../App'

export const Context = createContext()
export const ContextProvider = ({ children }) => {
  const [pendingRequestsLoader, setPendingRequestsLoader] = useState(true)
  const [activePendingRequestLoader, setActivePendingRequestLoader] = useState(
    true
  )
  const [user, setUser] = useState(null)
  const [pendingRequests, setPendingRequest] = useState([])
  const [activePendingRequest, setActivePendingRequest] = useState()
  const [types, setTypes] = useState()
  const [sort, setSort] = useState()
  const [firebaseAuth, setFirebaseAuth] = useState()
  const [remoteConfig, setRemoteConfig] = useState()

  const init = async () => {
    const app = await firebaseApp
    const auth = getAuth(app)
    setRemoteConfig(getRemoteConfig(app))
    setFirebaseAuth(auth)

    auth.onAuthStateChanged(user => {
      setUser(user)
    })
  }
  useEffect(() => {
    init()
  }, [])

  return (
    <Context.Provider
      value={{
        user,
        setUser,
        pendingRequests,
        setPendingRequest,
        activePendingRequest,
        setActivePendingRequest,
        types,
        setTypes,
        sort,
        setSort,
        pendingRequestsLoader,
        setPendingRequestsLoader,
        activePendingRequestLoader,
        setActivePendingRequestLoader,
        firebaseAuth,
        remoteConfig,
      }}>
      {children}
    </Context.Provider>
  )
}

export const useAppContext = () => {
  const _context = useContext(Context)

  if (!_context) {
    throw new Error('You have forgotten to use AppContext.')
  }

  return _context
}
