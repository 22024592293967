import React from 'react'
import { useLocation } from 'react-router-dom'

import PaymentFailed from '../../assets/images/payment-failed.svg'
import Button from '../../components/Button'

const PaymentStatus = () => {
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const status = searchParams.get('status')

  return (
    <div className="payment-status">
      <div className="container">
        <div className="content-wrapper">
          <img src={PaymentFailed} alt="Servbees Logo" className="img" />
          <h1 className="title-heading">
            Payment{' '}
            {status === 'success'
              ? 'Successful'
              : status === 'failed'
              ? 'Unsuccessful'
              : 'Payment Processing'}
          </h1>
        </div>
        <Button className="primary-btn">
          {status === 'success' || status === 'payment processing'
            ? 'Done'
            :'Try other payment methods'}
        </Button>
      </div>
    </div>
  )
}

export default PaymentStatus
