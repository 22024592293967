import { getAuth } from 'firebase/auth'
import { firebaseApp } from '../App'

export const checkToken = async () => {
  try {
    const app = await firebaseApp
    const timestamp = sessionStorage.getItem('token-timestamp')
    const auth = getAuth(app)
    if (
      timestamp &&
      Date.now() - +timestamp >= 3600 * 1000 &&
      auth().currentUser
    ) {
      const idToken = await auth().currentUser.getIdToken(true)
      const tokenTimestamp = Date.now().toString()
      sessionStorage.setItem('token', idToken)
      sessionStorage.setItem('token-timestamp', tokenTimestamp)
    }
  } catch (error) {
    console.log(error)
  }
}
